@import './src/assets/scss/variables.scss';

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;

  @include mq("desktop", max) {
    font-size: 14px;
  }

  @include mq("tablet-small", max) {
    font-size: 12px;
  }


}

body {
  font-family: $primary-font;
  color: $typo-primary;
}

span, small, p {
  &.baloo{
    font-family: $secondary-font;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.gray {
    color: #3b4f5b;
  }

  &.white {
    color: $white;
  }

  &.black{
    color: black;
  }

  &.baloo{
      font-family: $secondary-font;
  }

  font-family: $primary-font;
  color: $primary-blue;
  margin: 0;
  line-height: 1.15;
}

h1 {
  font-size: 2.7rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.9rem;
}

label {
  display: inline-block;
  margin: 0;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-size: inherit;
  font-weight: 600;
}

ul li {
  color: #3b4f5b;
  font-size: 1rem;
}


span {
    color: inherit;
    font-family: $primary-font;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
}

p,
address {
  color: #3b4f5b;
  font-size: 1rem;
  line-height: 1.5rem;
}

a {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration-line: none;
  font-weight: 200;
  color: $primary-blue;
  margin: 0.5rem 0;
  cursor: pointer;
}

p {
  &.white {
    color: $white;
  }
}

