.languageSelect{
  .mat-dialog-container{
    width: 6rem;
    min-height: 10rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(67, 81, 99, 0.12);
    border-radius: 1.5rem;
  }
}

.cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 95vh !important;
  max-height: 95svh !important; 
}

@media (max-width: 500px) {
  .full-mobile-screen{
    border-radius: 0px;
    max-width: 100vw !important;
    max-height: 100vh !important;
    max-height: 100svh !important; 
    height: 100vh;
    .mat-dialog-container{
      border-radius: 0px !important;
      padding: 0px !important;
    }
  }
}

.cdk-overlay-pane.video-popup { 
  width: 80%;
    aspect-ratio: 1.6;
    .mat-dialog-container {
      height: 100%;
    }      
}

.big-popup { 
  .mat-dialog-container {
    width: 32rem;
    height: 32rem;  
    max-width: calc(100vw - 10vmin) !important;
    max-height: calc(100vh - 10vmin) !important;
    max-height: calc(100svh - 10vmin) !important; 
  }      
}

.big-popup-changePassword { 
  .mat-dialog-container {
    width: 32rem;
    height: 32rem;
    overflow: hidden !important;  
    max-width: calc(100vw - 10vmin) !important;
    max-height: calc(100vh - 10vmin) !important;
    max-height: calc(100svh - 10vmin) !important; 
  }      
}

.medium-adaptive-popup {
  .mat-dialog-container {
    width: 28rem;
    max-width: calc(100vw - 10vmin) !important;
    max-height: calc(100vh - 10vmin) !important;
    max-height: calc(100svh - 10vmin) !important; 
  }
}

.all-test-reports-popup {
  .mat-dialog-container {
    width: 70rem;
    height: 50rem;
    max-width: calc(100vw - 10vmin) !important;
    max-height: calc(100vh - 10vmin) !important;
    max-height: calc(100svh - 10vmin) !important;
  }
}

.panel-curriculum-popup {
  @media (max-width: 500px) {
    width: 98vw;
  }
  width: 70vw;
  .mat-dialog-container {
    background-color: #2F80ED;
  }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {      
    
}
  
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
  
@media (min-width: 1025px) and (max-width: 1280px) {     
    
}
  
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
  
@media (min-width: 768px) and (max-width: 1024px) {    
    
}
  
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
  
@media (min-height: 600px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {    
    
}

/* 
  ##Device = IPhones (landscape)
  ##Screen = B/w 768px to 1024px
*/
  
@media (max-height: 599px) and (min-width: 768px) and (max-width: 1024px)  and (orientation: landscape) {

  .big-popup {
    .mat-dialog-container {
      width: calc(100vw - 10vh);
      height: 90vh;
    }   
  }

}
  
/* 
  ##Device = Low Resolution Tablets, Mobiles (Portrait)
  ##Screen = B/w 481px to 767px
*/
  
@media (min-width: 481px) and (max-width: 767px)  {    


}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
  
@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {

  .big-popup {
    .mat-dialog-container {
      width: calc(100vw - 10vh);
      height: 90vh;
    }    
  } 
  
}
  
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
  
@media (min-width: 320px) and (max-width: 480px) {

  .big-popup {
    .mat-dialog-container {
      width: 80vw;
      height: calc(100vh - 20vw);
      height: calc(100svh - 20vw);
    }    
  }

}

/* 
  ##Device = other Mobiles (Portrait)
  ##Screen = B/w 0 to 319px
*/
  
@media (min-width: 0px) and (max-width: 319px) {
    
  .big-popup {
    .mat-dialog-container {
      width: 90vw;
      height: calc(100vh - 10vw);
      height: calc(100svh - 10vw);
    }
  } 

}