/* Grayscale */
$black: #000000;
$white: #ffffff;


$primary-green: #77ac44;
$primary-grey: #e8e8e8;

$primary-orange: #f7ab00;
$primary-red: #f25f3e;

$primary-blue: #016fd0;
$light-blue: #e7eef2;
$blue: #e7eef3;
$back-blue: #0163c3;
$active-blue: #0279EE;

$typo-primary: #3b4f5b;
$form-span: #4688af;

$pop-title: #477a98;

$primary-font: "Noto Sans";
$secondary-font: "Baloo 2";

$scroll-button: #54799b;
$scroll-button-hover: #8cb7dc;
$scroll-button-active: #17558a;
$scroll-track: #c9d2d8;

$scroll-button-size: 6px;

$breakpoints: (
  "phone-small": 340px,
  "phone": 400px,
  "phone-wide": 500px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 770px,
  "tablet-spec": 900px,
  "ipad": 1024px,
  "tablet-wide": 1100px,
  "desktop": 1340px,
  "desktop-wide": 1440px,
  "full-hd": 1920px,
  "hd-h": 980px,
  "full-hd-h": 1080px,
  "4k": 2300px,
);
