section, header, nav {
  position: relative;

  &.gray {
    background: #e7eef3;
  }

  @include flex-vertical {
    align-items: center;
  }

  .border {
    position: absolute;
    width: 100%;
    height: 1rem;

    &.bottom {
      bottom: -0.25rem;
      transform: rotate(180deg);
    }

    &.top {
      top: -1rem;
      transform: rotate(180deg);
    }
  }
}

article.contentWrapper {
    max-width: 1200px;
    @include flex-vertical {
      align-items: center;
    }
}