@import './src/assets/scss/variables.scss';
@import './src/assets/scss/mixins.scss';

.inputGroup{
    label{
        margin: 0 0 0.5rem 1.5rem;
        align-self: flex-start;
        font-weight: 200;
    }
    
    position: relative;
    display: flex;
    height: 76px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    input {
        box-sizing: border-box;
        outline: none;
        border:none;	
        padding:1rem 1.5rem;
        border-radius: 2rem;
        font-size: 1rem;
        font-family: $primary-font;
        color: rgba(0, 0, 0, .8);
        font-weight: 200;
        background:rgba(0, 0, 0, .1);
        width: 100%;
        &::placeholder{
            color: rgba(0,0,0,.4);
            font-size: 1rem;
            font-weight: 200;
        }
    }

    button {
      width: 100%;
    }

    .mat-error{
        transition: all 0.3s linear;
        font-weight: 200 !important;
        align-self: flex-end;

        &.special {
            position: absolute;
            top: 60px;
            background: #ffffff;
            padding: 4px;
            z-index: 5;
            border-radius: 2px;
            margin-bottom: 4px;
            font-weight: 600!important;
            color: #e00000;
            border: 2px solid #cb0000;
        }
    }

    &.small {
        height: 60px;
    }
}

.customGroup {
    .mat-error {
        transition: all .3s linear;
        margin: -16px 0 16px;
        font-size: .75rem!important;
    }
}
