@import './src/assets/scss/variables.scss';

span.rhombus {
  width: 2.8rem;
  height: 2.8rem;
  background: $white;
  transform: matrix(0.71, -0.7, 0.77, 0.71, 0, 0);
}

div.languageCircle, a.languageCircle {
  @include flex-vertical {
    align-items: center;
  }

  cursor: pointer;
  margin: 0.75rem;

  .circle {
    width: 4.5rem;
    height: 4.5rem;
    border: 0.5rem solid white;
    background-position: center;
    background-size: contain;
    &.albanian {
      background-image: url("/assets/images/languages/albanian.png");
    }
    &.arabic {
      background-image: url("/assets/images/languages/arabic.png");
    }
    &.armenian {
      background-image: url("/assets/images/languages/armenian.png");
    }
    &.bulgarian {
      background-image: url("/assets/images/languages/bulgarian.png");
    }
    &.cantonese {
      background-image: url("/assets/images/languages/cantonese.png");
    }
    &.chinese {
      background-image: url("/assets/images/languages/chinese.png");
    }
    &.creole {
      background-image: url("/assets/images/languages/creole.png");
    }
    &.croatian {
      background-image: url("/assets/images/languages/croatian.png");
    }
    &.czech {
      background-image: url("/assets/images/languages/czech.png");
    }
    &.danish {
      background-image: url("/assets/images/languages/danish.png");
    }
    &.dari {
      background-image: url("/assets/images/languages/dari.png");
    }
    &.dutch {
      background-image: url("/assets/images/languages/dutch.png");
    }
    &.english {
      background-image: url("/assets/images/languages/english.png");
    }
    &.finnish {
      background-image: url("/assets/images/languages/finnish.png");
    }
    &.french {
      background-image: url("/assets/images/languages/french.png");
    }
    &.german {
      background-image: url("/assets/images/languages/german.png");
    }
    &.greek {
      background-image: url("/assets/images/languages/greek.png");
    }
    &.gujarati {
      background-image: url("/assets/images/languages/gujarati.png");
    }
    &.hawaiian {
      background-image: url("/assets/images/languages/hawaiian.png");
    }
    &.hebrew {
      background-image: url("/assets/images/languages/hebrew.png");
    }
    &.hindi {
      background-image: url("/assets/images/languages/hindi.png");
    }
    &.hungarian {
      background-image: url("/assets/images/languages/hungarian.png");
    }
    &.indonesian {
      background-image: url("/assets/images/languages/indonesian.png");
    }
    &.irish {
      background-image: url("/assets/images/languages/irish.png");
    }
    &.italian {
      background-image: url("/assets/images/languages/italian.png");
    }
    &.japanese {
      background-image: url("/assets/images/languages/japanese.png");
    }
    &.kazakh {
      background-image: url("/assets/images/languages/kazakh.png");
    }
    &.korean {
      background-image: url("/assets/images/languages/korean.png");
    }
    &.latin {
      background-image: url("/assets/images/languages/latin.png");
    }
    &.malay {
      background-image: url("/assets/images/languages/malay.png");
    }
    &.norwegian {
      background-image: url("/assets/images/languages/norwegian.png");
    }
    &.persian {
      background-image: url("/assets/images/languages/persian.png");
    }
    &.polish {
      background-image: url("/assets/images/languages/polish.png");
    }
    &.portuguese-br {
      background-image: url("/assets/images/languages/portuguese-br.png");
    }
    &.portuguese-eu {
      background-image: url("/assets/images/languages/portuguese-eu.png");
    }
    &.punjabi {
      background-image: url("/assets/images/languages/punjabi.png");
    }
    &.romanian {
      background-image: url("/assets/images/languages/romanian.png");
    }
    &.russian {
      background-image: url("/assets/images/languages/russian.png");
    }
    &.serbian {
      background-image: url("/assets/images/languages/serbian.png");
    }
    &.slovak {
      background-image: url("/assets/images/languages/slovak.png");
    }
    &.slovenian {
      background-image: url("/assets/images/languages/slovenian.png");
    }
    &.spanish {
      background-image: url("/assets/images/languages/spanish.png");
    }
    &.swahili {
      background-image: url("/assets/images/languages/swahili.png");
    }
    &.swedish {
      background-image: url("/assets/images/languages/swedish.png");
    }
    &.tagalog {
      background-image: url("/assets/images/languages/tagalog.png");
    }
    &.thai {
      background-image: url("/assets/images/languages/thai.png");
    }
    &.turkish {
      background-image: url("/assets/images/languages/turkish.png");
    }
    &.ukrainian {
      background-image: url("/assets/images/languages/ukrainian.png");
    }
    &.urdu {
      background-image: url("/assets/images/languages/urdu.png");
    }
    &.vietnamese {
      background-image: url("/assets/images/languages/vietnamese.png");
    }
    &.welsh {
      background-image: url("/assets/images/languages/welsh.png");
    }
  }

  span {
    margin-top: 1rem;
  }
}
