@import './src/assets/scss/variables.scss';

@mixin no-user-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

}
@mixin ui-wayfinding-labels {
  letter-spacing: 0.045em;
  text-transform: uppercase;
  font-weight:600;
}
@mixin flex-horizontal {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  @content;
}

@mixin flex-vertical {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  @content;
}


@mixin mq($width, $type: min, $orient: no) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @if $orient == no {
      @media only screen and (#{$type}-width: $width) {
        @content;
      }
    } @else {
      @media only screen and (#{$type}-width: $width) and (orientation:$orient) {
        @content;
      }
    }
    
  }
}

@mixin screen-maxl-height($height, $orient: landscape) {
  @if map_has_key($breakpoints, $height) {
    $height: map_get($breakpoints, $height);
    @if $orient == landscape {
      @media only screen and (max-height: $height) and (min-width: $height) and (orientation:landscape) {
        @content;
      }
    } @else {
      @if($orient == both) {
        @media only screen and (max-height: $height) {
          @content;
        }
      } @else {
        @media only screen and (max-height: $height) and (orientation:portrait) {
          @content;
        }
      }
      
    }
  }
}