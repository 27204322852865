/* You can add global styles to this file, and also import other style files */
@import './src/assets/scss/variables.scss';
@import './src/assets/scss/material.scss';
@import './src/assets/scss/mixins.scss';
@import './src/assets/scss/typography.scss';
@import './src/assets/scss/geometry.scss';
@import './src/assets/scss/forms.scss';
@import './src/assets/scss/buttons.scss';
@import './src/assets/scss/bootstrap.scss';
@import './src/assets/scss/popups.scss';

html {
  height: 100vh;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available; 
    min-height: -webkit-fill-available;
  }
  height: 100svh;
  min-height: 100svh;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available; 
    min-height: -webkit-fill-available;
  }
  height: 100svh;
  min-height: 100svh;
}

.errorNotificationMessage {
  background-color: rgba(255, 0, 0, 0.603);
  color: white;
  font-size: 20px;
}

.successNotificationMessage {
  background-color: rgba(5, 206, 89, 0.835);
  color: black;
  font-size: 18px;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  outline: none;
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 20px !important;
}

.mat-stepper-horizontal {
  width: 100% !important;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-horizontal-content-container {
  width: 100%;
  padding: 0 !important;
  height: 100%;
}

.mat-expansion-panel-body {
  @include mq('tablet-wide', max) {
    padding: 0 !important;
  }
}

.mat-expansion-panel-content[style*="visibility: hidden"] * {
  height: 0;
}

.mat-select-trigger {
  height: 100% !important;
}

mat-panel-description {
  @include mq('tablet-wide', max) {
    display: none !important;
  }
}

.game {
  width: 100%;
}

section {
  &.storiesFilters {
    .mat-paginator {
      background: white;
      font-size: 1rem;
      align-self: flex-end;
    }
  }

  &.booksFilters {
    .mat-paginator {
      background: $light-blue;
      font-size: 1rem;
      align-self: flex-end;
    }
  }

  &.songsFilters {
    .mat-paginator {
      background: white;
      font-size: 1rem;
      align-self: flex-end;
    }
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

mat-video .volume-control {
  button {
    margin: 0 1rem !important;
  }
}

.menuDialog {
  @include screen-maxl-height('tablet-small') {
    height: unset !important;
  }

  @include screen-maxl-height('phone-wide') {
    height: unset !important;
  }

  .mat-dialog-container {
    box-shadow: none;
    background: transparent;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.transparentDialog {
  .mat-dialog-container{
    background: transparent;
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, 0.8);

    &.confetti {
      background-image: url(../images/anim/confetti.gif);
    }
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #ffffff7a;
}

.registrationWrapper
  .mat-form-field-appearance-legacy
  div.mat-form-field-underline {
    background: none;
}

.send-btn.report {
  position: absolute;
  z-index: 3;
  left: 2rem;
  bottom: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.report_white-color {
    color: #fff;
  }
  img {
    width: 50px;
  }
}
input.mat-input-element,
.mat-form-field-label,
.mat-select-value,
.mat-select-trigger {
  min-height: 20px;
}

.bugReports:not(.link-button) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  bottom: 2.8rem;
  right: 1.5rem;
  position: fixed;
  z-index: 99;

  @include mq('tablet-spec', max) {
    bottom: auto;
    top: 1.1rem;
    right: 4.7rem;
    height: 45px;

    img {
      width: 45px;
    }
  }
  @include mq('phone-wide', max) {
    bottom: auto;
    top: 1.1rem;
    right: 4.7rem;
    height: 45px;

    img {
      width: 45px;
    }
  }
  @include screen-maxl-height("phone-wide") {
    bottom: auto;
    top: 1.1rem;
    right: 4.7rem;
    height: 45px;

    img {
      width: 45px;
    }
  }
}

mat-option {
  span.mat-option-text {
    display: flex;
    justify-content: space-between;
    img.langSelectImg {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin: auto 0;
    }
  }
}

.lessonsFilters {
  .filter {
    button.search {
      font-weight: 800;
    }
  }
}

.lessonSelect {
  @include mq("tablet", max) {
    min-width: fit-content !important;
  }
}

.scroll-modern {
  &::-webkit-scrollbar {
    width: $scroll-button-size;
    height: $scroll-button-size;
  }
  &::-webkit-scrollbar-button {
      width: $scroll-button-size;
      height: $scroll-button-size;
  }
  &::-webkit-scrollbar-thumb {
      background: $scroll-button;
      border: 0px none #ffffff;
      border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: $scroll-button-hover;
  }
  &::-webkit-scrollbar-thumb:active {
      background: $scroll-button-active;
  }
  &::-webkit-scrollbar-track {
      background: $scroll-track;
      border: 4px none #ffffff;
      border-radius: 50px;
  }
}

.inner-link {
  display: inline;
  overflow-wrap: anywhere;
}

/* LANDINGS */
.landing {
  section.mobileHide {
    @include mq("phablet", max) {
      display: none;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.75rem;

    &.blue {
      color: #016fd0;
    }
  }
  a {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h2 {
    font-size: 3rem;
  }
  h4 {
    font-size: 1.5rem;

    &.red {
      color: #ec5d3d;
    }
  }
  .dinoLink {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 !important;
    font-size: 1.75rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  
  
}

.subscriptionsWrapper{
  .inner-link {
    display: inline;
    font-size: 1rem;
  }
}
.iagree {
  .mat-checkbox-inner-container {
    width: 12px;
    height: 12px;
  }
  .mat-checkbox-layout {
    white-space: unset;
  }
}


.inner-link {
  display: inline;
}

.hideBlock {
  display: none!important;
}

mat-form-field.select-dinostyle {
  background-color: #fff;
  border-radius: 2rem;
  padding: 0 1rem 0.3rem 0;
  box-sizing: border-box;
  margin-bottom: 1rem;

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-value {
    padding-left: 22px;
    padding-top: 2px;
  }

  &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    margin-top: 2px;
  }

}


.paymentPlansSelect{
  .mat-form-field-flex,
  .mat-form-field-underline::before{
    background: transparent;
    width: 29rem;
  }
  mat-label{
    color: $typo-primary;
    font-size: 1.3rem;
    line-height: 1.5rem;
    height: 3rem;
  }
  .mat-select-trigger{
      .mat-select-arrow-wrapper{
    transform: translateY(-25%);
    }
    .mat-select-arrow{
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 12px solid $typo-primary;
    }
  }

}

.mat-tab-labels {
  @include mq("phablet", max) {
    flex-direction: column;
    &+.mat-ink-bar {
      opacity: 0;
    }
  }
  .mat-tab-label {
    @include mq("tablet-spec", max) {
      min-width: 140px;
    }
    &.mat-tab-label-active {
      font-weight: bold;
    }
  }
}

.svgImage {
  @include mq("phablet", max) {
    max-width: 22rem!important;
  }
}

.notific {
  img, video {
      width: 100%;
  }
}

app-localization-dropdown {
  .mat-form-field-appearance-outline .mat-form-field-outline, .mat-form-field-appearance-outline .mat-form-field-outline-thick, .mat-select-value, .mat-select-arrow, .mat-form-field-label {
    color: #fff;
  }
}

.test app-localization-dropdown {
  .mat-form-field-appearance-outline .mat-form-field-outline, .mat-form-field-appearance-outline .mat-form-field-outline-thick, .mat-select-value, .mat-select-arrow, .mat-form-field-label {
    color: #3676a3;
  }
}

.mat-option-text .small-tip {
  opacity: 0.6;
  font-size: 0.9rem;
}

div.ng2-pdf-viewer-container {
  overflow-x: unset;
}

/* BLOG STYLES */
.blog {
  img {
    max-width: 100%;
    height: auto;
  }

  ul li {
    font-size: 1.25rem;
  }

  a {
    display: inline;
    font-weight: unset;
  }
}

.mat-mdc-radio-button label {
  display: contents;
  color: #fff;
}

.orange {
  color: #F7AB00;
}

.mat-select-arrow {
  color: #0e50a8;
}

mat-option {
  img {
    height: 28px;
    align-self: center;
  }
}

.newDinoStyle {
  input {
    width: 100%;
    padding: 0 4px;
    border-radius: unset;
    background: unset;
  }

  label {
    margin: 0 0 0 4px;
  }

  .mat-form-field-infix {
    top: -2px;
  }

  .mat-form-field-flex {
    background: #fff;
    border-radius: 32px;
    padding: 4px 18px;
  }
  .mat-form-field-underline {
    border-right: 20px solid;
    border-right-color: #00000000;
    box-sizing: border-box;
    border-left: 20px solid;
    border-left-color: #00000000;
  }

  .mat-checkbox-frame {
    border-color: #fff;
  }

  .mat-checkbox-inner-container {
    margin-right: 16px;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  .mat-checkbox-label {
    line-height: 18px;
  }
}

.full-width + ng-component {
  width: 100%;
}