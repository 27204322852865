@import './src/assets/scss/variables.scss';

.mat-raised-button{
    border-radius: 2rem !important;
    font-weight: 800;
}


button.dino{
    width: 100%;
    cursor: pointer;
    border:none;	
    padding:1rem 1.5rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 200;
    color: rgba(0,0,0,.4);
    font-weight: 200;
    background:rgba(0,0,0,.1);
    width: 100%;
    &:hover{
        background: rgba(0,0,0,.3);
    }
}